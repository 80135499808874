
























import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import BackButtonLayout from '@/layouts/management/BackButtonLayout.vue';
import CourseFormTemplate from '@/components/courses/CourseFormTemplate.vue';
import { CourseStores } from '@/store/course/index';
import { DropdownItem } from '@/models/dropdown';
import { CourseItemCreateRequest, CourseItemCreateRequestForAxios } from '@/models/courses';
import { SubjectStores } from '@/store/subjects';
import { FormStores } from '@/store/forms';
import { TutorStores } from '@/store/tutors';
import { LanguageStores } from '@/store/languages';
import { monthDurationDropdown, VideoFileSizeLimit, weekDaysDropdown, weekDurationDropdown, repeatTypesDropdown } from '@/utils/constants';
import moment from 'moment';
import { uploadAttachment } from '@/utils/attachments';
import { RoleStores } from '@/store/roles';
import { getDifferenceInMinutes } from '@/utils/moment';
@Component({
  components: { Page, BackButtonLayout, CourseFormTemplate },
})
export default class AddCourses extends Vue {
  courseCreateStore = CourseStores.create;
  courseStateStore = CourseStores.state;
  subjectListStore = SubjectStores.list;
  levelListStore = FormStores.list;
  tutorListStore = TutorStores.list;
  languageListStore = LanguageStores.list;
  roleListStore = RoleStores.list;
  courseLanguageData: DropdownItem[] = [];
  courseSubjectData: DropdownItem[] = [];
  courseLevelData: DropdownItem[] = [];
  courseTutorData: DropdownItem[] = [];
  courseTimetableDays: DropdownItem[] = [];
  courseMonthsDuration: DropdownItem[] = [];
  courseWeekDuration: DropdownItem[] = [];
  courseRepeatTypes: DropdownItem[] = [];
  courseSubjectPrefixId = -1;
  courseLevelPrefixId = -1;
  isCourseLevelAutofilled = false;
  formRequestData: CourseItemCreateRequest = {
    formId: 0,
    subjectId: 0,
    isFeatured: false,
    languageId: 0,
    tuitionId: 0,
    teacherId: 0,
    courseName: '',
    price: '',
    discountPrice: '',
    description: '',
    startDate: new Date(),
    endDate: new Date(),
    repeatType: 'Never',
    repeatOnDay: 'number',
    repeatNumber: 1,
    weekDays: [],
    courseStartTime: new Date(),
    courseEndTime: new Date(),
    videoUrl: '',
    backgroundImageUrl: '',
    courseImageUrl: '',
    videoThumbnailUrl: '',
    studyTrack: {},
  };

  get layoutTitle() {
    return `Add New Course`;
  }

  get backRoute() {
    return `/courses`;
  }

  get languages() {
    return this.courseLanguageData;
  }

  get subjects() {
    return this.courseSubjectData;
  }

  get levels() {
    return this.courseLevelData;
  }

  get tutors() {
    return this.courseTutorData;
  }

  get timetableDays() {
    return this.courseTimetableDays;
  }

  get months() {
    return this.courseMonthsDuration;
  }

  get weeks() {
    return this.courseWeekDuration;
  }

  get isCourseNameDuplicated() {
    return this.courseCreateStore.isCourseNameDuplicated;
  }
  
  get repeatTypes() {
    return this.courseRepeatTypes;
  }

  // TODO: Replace this with the API calls
  mounted() {
    this.courseTimetableDays = weekDaysDropdown;
    this.courseMonthsDuration = monthDurationDropdown;
    this.courseWeekDuration = weekDurationDropdown;
    this.courseRepeatTypes = repeatTypesDropdown;
    this.courseCreateStore.setTimeOut(null);
    this.roleListStore.retrieveRoles();
    this.subjectListStore.retrieveSubjects({});
    this.languageListStore.retrieveLanguages();
    // Add autofill based on folder level
    switch(this.courseStateStore.courseFolderState.length) {
      case 2: {
        this.courseSubjectPrefixId = this.courseStateStore.courseFolderState[this.courseStateStore.courseFolderState.length - 1].id;
        this.getFormDropdown(this.courseSubjectPrefixId);
        break;
      }
      case 3: {
        this.courseSubjectPrefixId = this.courseStateStore.courseFolderState[this.courseStateStore.courseFolderState.length - 2].id;
        this.courseLevelPrefixId = this.courseStateStore.courseFolderState[this.courseStateStore.courseFolderState.length - 1].id;
        this.getFormDropdown(this.courseSubjectPrefixId);
        this.isCourseLevelAutofilled = true;
        break;
      }
      default: {
        break;
      }
    }
  }

  getFormDropdown(subjectId: number) {
    this.courseTutorData = [];
    this.levelListStore.retrieveForms({
      subjectid: subjectId
    });
  }

  getTutorDropdown() {
    const teacherIndex = this.roleListStore.response.findIndex((role) => {
      return role.shortname === 'teacher';
    });
    if (teacherIndex > -1) {
      this.tutorListStore.retrieveTutors(this.roleListStore.response[teacherIndex].shortname);
    }
  }

  resetNameError(payload: boolean) {
    this.courseCreateStore.updateIsCourseNameDuplicated(payload);
  }

  @Watch(`subjectListStore.response`)
  updateSubjectDropdown() {
    if (this.subjectListStore.response) {
      this.courseSubjectData = [];
      this.subjectListStore.response.objects.forEach((item) => {
        this.courseSubjectData.push({
          text: item.subjectName,
          value: item.id.toString(),
          uniqueKey: `${item.id}_`,
        });
      });
    }
  }

  @Watch(`levelListStore.response`)
  updateFormDropdown() {
    if (this.levelListStore.response) {
      this.courseLevelData = [];
      this.levelListStore.response.objects.forEach((item) => {
        this.courseLevelData.push({
          text: item.formName,
          value: item.id.toString(),
          uniqueKey: `${item.id}_`,
        });
      });
      // This condition is only when the subject and level has been set from the beginning
      if (this.isCourseLevelAutofilled) {
        this.isCourseLevelAutofilled = false;
        this.getTutorDropdown();
      }
    }
  }

  @Watch(`tutorListStore.response`)
  updateTutorDropdown() {
    if (this.tutorListStore.response) {
      this.courseTutorData = [];
      this.tutorListStore.response.objects.forEach((item) => {
        this.courseTutorData.push({
          text: `${item.name} (${item.email})`,
          value: item.id.toString(),
          uniqueKey: `${item.id}_`
        });
      });
    }
  }

  @Watch(`languageListStore.response`)
  updateLanguageDropdown() {
    if (this.languageListStore.response) {
      this.courseLanguageData = [];
      this.languageListStore.response.forEach((data) => {
        this.courseLanguageData.push({
          text: data.name,
          value: data.id.toString(),
          uniqueKey: `${data.id}_`
        });
      });
    }
  }

  async createCourse(payload: CourseItemCreateRequest) {
    //TODO: Update image and video attachments
    const onlyStartDate = moment(payload.startDate).format('YYYY-MM-DD');
    const onlyStartTime = moment(payload.courseStartTime).format(`HH:mm:ss`);
    const combinedStartDateTime = moment(onlyStartDate + 'T' + onlyStartTime).toDate();
    const formattedDate = moment(combinedStartDateTime).format(`YYYY-MM-DDTHH:mm`);

    const onlyEndDate = moment(payload.endDate).format('YYYY-MM-DD');
    const onlyEndTime = moment(payload.courseEndTime).format(`HH:mm:ss`);
    const combinedEndDateTime = moment(onlyEndDate + 'T' + onlyEndTime).toDate();
    const formattedEndDate = moment(combinedEndDateTime).format(`YYYY-MM-DDTHH:mm`);
    let data: Partial<CourseItemCreateRequestForAxios> = {
      formId: payload.formId,
      subjectId: payload.subjectId,
      languageId: payload.languageId,
      tuitionId: payload.tuitionId,
      teacherId: payload.teacherId,
      courseName: payload.courseName,
      price: payload.price,
      description: payload.description,
      startDate: formattedDate,
      endDate: formattedEndDate,
      repeatType: payload.repeatType,
      repeatOnDay: payload.repeatOnDay,
      repeatNumber: payload.repeatNumber,
      weekDays: payload.weekDays,
      isFeatured: payload.isFeatured,
      videoUrl: payload.videoUrl,
      backgroundImageUrl: "",
      courseImageUrl: "",
      videoThumbnailUrl: "",
      discountPrice: payload.discountPrice,
    };
    if (
        typeof payload.videoUrl === 'string' && 
        payload.videoUrl
    ) {
        data.videoUrl = payload.videoUrl;
    }

    var currentTimestamp = "_" + new Date().valueOf(); // to make unique file name
    if (
      typeof payload.backgroundImageUrl !== 'string' && 
      payload.backgroundImageUrl &&
      payload.backgroundImageUrl.originalFile
    ) {
      const backgroundResponse = await uploadAttachment(
        payload.backgroundImageUrl.originalFile,
        payload.courseName + currentTimestamp,
        `background`
      );
      data.backgroundImageUrl = backgroundResponse;
    }
    if (
      typeof payload.videoThumbnailUrl !== 'string' && 
      payload.videoThumbnailUrl &&
      payload.videoThumbnailUrl.originalFile
    ) {
      const backgroundResponse = await uploadAttachment(
        payload.videoThumbnailUrl.originalFile,
        payload.courseName + currentTimestamp + "_video_thumbnail",
        `thumbnail`
      );
      data.videoThumbnailUrl = backgroundResponse;
    }

    if (
      typeof payload.courseImageUrl !== 'string' && 
      payload.courseImageUrl &&
      payload.courseImageUrl.originalFile
    ) {
      const courseResponse = await uploadAttachment(
        payload.courseImageUrl.originalFile,
        payload.courseName + currentTimestamp,
        `thumbnail`
      );
      data.courseImageUrl = courseResponse;
    }

    // Check Study Track and add them if the data is not null or undefined

    if (payload.studyTrack[`january`]) {
      data.january = payload.studyTrack[`january`];
    }
    if (payload.studyTrack[`february`]) {
      data.february = payload.studyTrack[`february`];
    }
    if (payload.studyTrack[`march`]) {
      data.march = payload.studyTrack[`march`];
    }
    if (payload.studyTrack[`april`]) {
      data.april = payload.studyTrack[`april`];
    }
    if (payload.studyTrack[`may`]) {
      data.may = payload.studyTrack[`may`];
    }
    if (payload.studyTrack[`june`]) {
      data.june = payload.studyTrack[`june`];
    }
    if (payload.studyTrack[`july`]) {
      data.july = payload.studyTrack[`july`];
    }
    if (payload.studyTrack[`august`]) {
      data.august = payload.studyTrack[`august`];
    }
    if (payload.studyTrack[`september`]) {
      data.september = payload.studyTrack[`september`];
    }
    if (payload.studyTrack[`october`]) {
      data.october = payload.studyTrack[`october`];
    }
    if (payload.studyTrack[`november`]) {
      data.november = payload.studyTrack[`november`];
    }
    if (payload.studyTrack[`december`]) {
      data.december = payload.studyTrack[`december`];
    }
    this.courseCreateStore.createNewCourse(data);
  }

  @Watch(`courseCreateStore.response`)
  redirectToCourseManagement() {
    if (this.courseCreateStore.response) {
      this.courseStateStore.updateCourseIsModified(true);
      this.$router.push({
        path: `/courses`,
      });
    }
  }

  @Watch(`courseCreateStore.isTimeout`)
  redirectToCourses() {
    if (this.courseCreateStore.isTimeout) {
      this.courseStateStore.updateCourseIsModified(true);
      this.$router.push({
        path: `/courses`
      });
    }
  }
}
